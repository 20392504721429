
/*==== DetailsList ====*/
.ms-DetailsHeader{
    background-color: rgb(250, 250, 250);
}

.ms-DetailsHeader-cellName{
    font-weight: 700;
}
/*=====================*/


/*==== Pivot ====*/
.ms-Pivot-text{
    font-size: 1rem;
    font-weight: 600;
}

.pivot-top-selected .ms-Pivot{
    background-color: white;
    box-shadow:0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin-bottom: 0px; 
    padding-bottom: 0px;
}

.ms-Pivot{
    background-color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    margin-bottom: 2px;
    padding-bottom: 0px;
}

.pivot-top-selected .ms-Pivot-link.is-selected::before{
    top: 0px;
}
/*===============*/

/*==== TagPicker ====*/
.ms-PeoplePicker{
    background-color:white;
    max-width: 16rem;
}
/*===================*/

/*==== Progress indicator ====*/
.ms-ProgressIndicator-itemProgress{
    padding:0px
}
/*===================*/