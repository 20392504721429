.login-root{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-image: var(--login-bg);
}

.login-container{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    place-items: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 56rem;
    height: 500px;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.login-form{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 0.5rem;
    flex-direction: column;
    padding: 2rem;
    background-color: white
}

.login-form-title{
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
}

.login-form-company{
    font-size: 1.5rem;
    font-weight: 600;
}

.login-form-company span{
    color:#84bd00
}

.login-form-logos-comtainer{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.login-form-inputs{
    width: 100%;
    max-width: 20rem;
}

.login-form-inputs-bg{
    background-color: white;
}

.login-form-logos-comtainer img{
    max-height: 4rem;
    object-fit: cover;
}

.login-container-img{
    height: 100%;
    max-width: 100%;
    display: none;
    object-fit: cover;
}

.login-loading{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

@media (min-width: 768px) {
    .login-container{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .login-container-img{
        display: block;
    }
}